const size = {
    mobileXS: '281px',
    mobileS: '321px',
    mobileM: '375px',
    mobileL: '425px',
    mobile: '500px',
    tablet: '768px',
    laptop: '1024px',
    desktop: '2560px',
    height: '1024px',
  };
export const device = {
    mobileXS: `(max-width: ${size.mobileXS})`,
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`,
    height: `(min-height: ${size.height})`
  };

export const colors = {
    primary: '#364456',
    secundary: '#012739',
    clear: '#FBFBFB',
    red: '#FF7489',
    green: '#A9F2AF',
    gold: '#FFC374',
    blue:'#388CFF',
    gray: "#9B9B9B",
    white: "#ffffff",
    black: "#000000",
    shadow: "#03326956",
    disabled: "#EDEDED",   
};