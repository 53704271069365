import React from 'react';
import styled from 'styled-components';
import { colors } from 'resources/Theme';
import Stars from 'components/Stars';
import { device } from 'resources/Theme';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: max(70px, 60%);
    margin: 0 auto;
    padding: 0;

    && h1 {
        color: ${({color}) => ( color || colors.white)};
        padding: 10px 0 8px;
        @media ${device.mobile} { 
          padding: 8px 0 3px;
        }
    }
`;
const Rating = ({title, value}) => {
    const percent = !value? "" : value === '-' ? colors.white : value <= 4 ? colors.red : colors.green;

    return (
    <Container color={percent}>
       <label className="subtitle">{title}</label>
       <h1>{!!value ? value : '-' }</h1>
       <Stars value={value} id={title}/>
    </Container>
  );
}

export default Rating;