import React from 'react';
import styled from 'styled-components';
import { device, colors } from 'resources/Theme';
import Translate from 'i18n/translate';

const Wrap = styled.div`
    margin-top: 10px;
`;
const Container = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: ${({percent}) => (percent || 'repeat(auto-fit, 50%)')};
    grid-template-rows: auto;
    padding-bottom: 5px;
    justify-content: center;
    text-align: center;

    div:nth-child(1) {
        justify-content: flex-start;
        text-align: left;
      }
    div:nth-last-child(1) {
        justify-content: flex-end;
        text-align: right;
    }
`;
const Bar = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: ${({percent}) => (percent || 'repeat(auto-fit, 10%)')};
    grid-template-rows: 6px;
    background: ${colors.secundary};
    border-radius: 30px;

    @media ${device.mobile} { 
        grid-template-rows: 6px; 
    }
    div:nth-child(2n+1) {
        background: ${({color}) => (color || colors.gray)};
    }
    div:nth-child(1) {
        border-radius: 30px 0 0 30px;
        background: ${({color}) => (color || colors.green)};
      }
    div:nth-last-child(1) {
        border-radius: 0 30px 30px 0;
        background: ${({color}) => (color || colors.red)};
    }
`;
const Filler = styled.div`
    width: 100%;
    background: ${({color}) => (color || colors.gold)};
`;
const Item = styled.div`
    display: flex;
    flex-direction: column;
`;
const PercentItem = ({items}) => {
    const value = !!items && items.map((item) => item[Object.keys(item)] );
    let total = !!value && value.reduce((a, b) => a + b, 0); 
    const barePercent = (total === 0) ? value : value && value.map((number) => Math.round((number === 0 ? number : number/total)*100));
     
    let maxValue = !!barePercent && barePercent.reduce((a, b) => a + b, 0);
    const dif = !!maxValue && !!barePercent && (maxValue < 100) ? (barePercent[barePercent.length - 1] += (100 - maxValue)) 
    : (maxValue > 100) ? (barePercent[barePercent.length - 1] -= (maxValue - 100)) 
    : barePercent[barePercent.length - 1];
    
    const percent = barePercent && barePercent.map((number) => `${number}%`);
    const length = percent.length;
    const lengthGrid = barePercent && barePercent.map(() => `${ 100/length}%`);
    const grid = lengthGrid;

    return(
        <Wrap>   
            <Container percent={grid}>
                {!!items && items.map((item, i) =>
                    <Item key={i}>
                        <label>{ Translate(Object.keys(item))}</label>
                        <span>{percent[i]} - {value[i]}</span>
                    </Item>
                )}  
            </Container>
            <Bar percent={percent}>
                {!!items && items.map((item, i) => <Filler key={i}/>)}
            </Bar>
        </Wrap>
    );
};

export default PercentItem;