import React from 'react';
import styled from 'styled-components';
import ProgressBar from 'components/ProgressBar';
import Translate from 'i18n/translate';
import { device } from 'resources/Theme';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    row-gap: 15px;
    width: 100%;
    margin: 30px auto 18px;
    
    @media ${device.tablet} { 
      margin: 20px 0 8px;
    }
    @media ${device.mobile} { 
      margin: 18px 0 3px;
    }
    @media ${device.mobileS} { 
      width: 95%;
      margin: 0 auto;
    }
`;

const Opinions = ({data}) => (
    <>
    <h3 className='top'>{Translate('Customers opinion of you')}</h3>
    <Container >
      <ProgressBar items={data && data[0]}/>
      <ProgressBar items={data &&  data[1]}/>
      <ProgressBar items={data &&  data[2]}/>
    </Container>
    </>
  );

export default Opinions;