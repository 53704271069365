import React from 'react';
import Header from 'components/Header';
import Graphics from 'containers/Graphics';
import RatingRanking from './containers/RatingRanking';
import Experiences from './containers/Experiences';
import Opinions from './containers/Opinions';

const Server = ({data}) => {
  const info = data?.info;
  const URL = 'https://appsinti.com/images/common/persons/';
  const IMG = `${URL}${info?.id}.png`;
  return (
    <>
     <Header img={IMG} name={info?.name} type={info?.type} />
     <Graphics date={info?.date}>
       <RatingRanking data={data?.rating}/>
       <hr />
       <Experiences data={data?.experiences}/>
       <hr />
       <Opinions data={data?.opinions}/>
     </Graphics>
    </>
  );
}

export default Server;
