import React from 'react';
import styled from 'styled-components';
import PieChart from 'components/PieChart';
import PercentItem from 'components/PercentItem';
import Translate from 'i18n/translate';
import Color from 'resources/utils/color'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: max-content;
    margin: 0px auto;
    text-align: left;
    
    p {
        margin-top: 10px;
        letter-spacing: 0.36px;
     }
`;

function PieColumn({data}) {

    const value = !!data && Object.values(data[1]);
    let total = !!value && value.reduce((a, b) => a + b, 0); 
    const percent = (total === 0) ? 0 : value && value.map((number) => Math.round((number/total)*100));
    
    const pieColor= [];
    return (
    <Container>
        <PieChart percent={percent} color={pieColor} />
        <p className='bold title'>{!!data &&  Translate(data[0])}</p>
        {!!data && Object.keys(data[1]).map((tag, i) => 
            pieColor.push(Color(tag)) &&  
            <PercentItem 
            key={i}
            title={Translate(tag)} 
            percent={percent[i]} 
            total={value[i]} 
            color={Color(tag)} 
            />        
        )}    
    </Container>
  );
}

export default PieColumn;