import React from 'react';
import {
  Container,
  Header,
  Grid,
  Row
} from './styles';

const DisabledTable = ({title, data}) => {
  const columns = 2;
  return (
    <Container>

      <h3>{title}</h3>

      <Header columns={columns}>
          <div>Item Name</div>
          <div>Who made the change</div>
      </Header>
      
      <Grid>
        { data?.map((value, i) =>    
          <Row 
            columns={columns} 
            className='row' 
            key={i}
            >
              <div>{value.product}</div>
              <div>{value.user}</div>
          </Row> 
        )}  
      </Grid>
    </Container>
    );
};

export default DisabledTable;