import React from 'react';
import styled from 'styled-components';
import { device, colors } from 'resources/Theme';

const Container = styled.div`
    background: ${({slices}) => ( slices ? `conic-gradient(${slices})` : colors.secundary )};
    border-radius: 50%;
    width: 80px;
    height: 80px;
    @media ${device.tablet} { 
      width: 50px;
      height: 50px;
    }
`;

const PieChart = ({percent, color}) => {
  const prev = [];
  const offset = !!percent && percent.map(num => prev.push(num) && prev.reduce((a, b) => a + b, 0));
  const sliceStructure = Object.keys(offset).map(k => `${color[k]} 0 ${offset[k]}%` );

  return (
    <Container slices={percent === 0 ? '' : sliceStructure} />
  );
}

export default PieChart;