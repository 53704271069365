import { useState, useEffect } from 'react';
import Server from 'reports/Server';
import Error from 'reports/Error';
import Inactive from 'reports/86Inactive';
import Loading from 'reports/Loading';
import getInfo from 'core/api';


const App = () => {
    const [report, setReport] = useState(Loading);
    const [data, setData] = useState();

    const showReport = (type, data, isPreview) => {
        switch (type) {
            case 'server': return setReport(<Server data={data} />);
            case 'inactive': return setReport(<Inactive data={data} isPreview={isPreview}/>);
            default:return Error;
        }
    }

    useEffect(() => {
        const uid = window.location.pathname;
        const urlParams = new URLSearchParams(window.location.search);
        const isPreview = urlParams.get('preview') === "true";

        if (!uid) {
            setReport(Error);
            return;
        }

        getInfo(uid)
            .then(data => {
                setData(data.payload)
                showReport(data.type, data.payload, isPreview)    
            })
            .catch((e) => {
                console.log(e)
                setReport(Error)
            });
    }, []);
    
    useEffect(() => {
        showReport();
    }, [data])


    return report;
};

export default App;