import React from 'react';
import styled from 'styled-components';
import Rating from '../components/Rating';
import Badges from '../components/Badges';
import { device } from 'resources/Theme';
import Translate from 'i18n/translate';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 15px;
    width: min(100%, 900px);
    margin: 30px auto 18px;
    align-items: end;

    @media ${device.tablet} { 
      row-gap: 10px;
      margin: 20px 0 8px;
    }
    @media ${device.mobile} { 
      margin: 18px 0 3px;
    }
`;

function RatingRanking({data}) {
  const today = data?.today;
  const week = data?.week;
  const month = data?.month;
  const lifetime = data?.lifetime;
  return (
    <>
    <h3 className='top'>{Translate('Your Rating and Ranking')}</h3>
    <Container >
       <Rating title={Translate('Today')} value={today?.avg} />
       <Rating title={Translate('This Week')} value={week?.avg} />
       <Rating title={Translate('This Month')} value={month?.avg} />
       <Rating title={Translate('Lifetime')} value={lifetime?.avg} />     

       <Badges place={today?.place} total={today?.total}/>
       <Badges place={week?.place} total={week?.total}/>
       <Badges place={month?.place} total={month?.total}/>
    </Container>
    </>
  );
}

export default RatingRanking;