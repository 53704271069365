import React from 'react';
import styled from 'styled-components';
import Second from 'resources/images/2nd.svg';
import First from 'resources/images/1st.svg';
import Third from 'resources/images/3rd.svg';
import { device, colors } from 'resources/Theme';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
`;

const Img = styled.img`
    margin: 0px auto;
    width: max(34px , 34px + 1vw );
    height: max(47px , 47px + 1vw );
    margin-bottom: 10px;

    @media ${device.tablet} { 
      width: 34px;
      height: 47px;
      margin-bottom: 6px;
    }
`;

const Circle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid ${colors.white};
    opacity: 0.8;

    margin: 0px auto;
    width: max(34px , 34px + 1vw );
    height: max(34px , 34px + 1vw );
    margin-bottom: 10px;

    @media ${device.tablet} { 
      width: 34px;
      height: 34px;
      margin-bottom: 6px;
    }
`;
const Badges = ({place, total}) => {
const badge = place === 1? <Img src={First}/> 
              : place === 2? <Img src={Second}/> 
              : place === 3? <Img src={Third}/> 
              : <Circle><h2>{place <= total ? place : '-'}</h2></Circle>; 
const position = place === 1? '1st' : place === 2? '2nd' : place === 3? '3rd' : place;

const hasInfo = (!!position && !!total);

  return (
    <Container>
      { (hasInfo) && <> {badge}  </>}
      <span>{(hasInfo) && <> {position} of {total}</> } </span>
    </Container>
  );
}

export default Badges;