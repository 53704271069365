import React from 'react';
import styled from 'styled-components';
import { device, colors } from 'resources/Theme';
import Translate from 'i18n/translate';

const Container = styled.div`
    display: grid;
    grid-template-columns: calc(48px + 2vw) auto;
    grid-column-gap: calc(20px + 2vw);
    width: 90%;
    height: 130px;
    margin: 0px auto;
    justify-content: center;
    align-items: center;

    h2{
      text-transform: uppercase;
    }

    @media ${device.tablet} { 
      height: 80px;
    }
    @media ${device.mobile} { 
      width: 95%;
      grid-template-columns: 48px auto;
      grid-column-gap: 25px;
    }
    @media ${device.mobileS} { 
      grid-column-gap: 15px;
    }
    @media ${device.mobileXS} { 
      grid-column-gap: 5px;
    }
`;

const Img = styled.img`
    border-radius: 50%;
    margin: 0px auto;
    background-color: ${colors.clear};
    width: calc(48px + 2vw);
    height: calc(48px + 2vw);
    @media ${device.mobile} { 
      width: 48px;
      height: 48px;
    }
`;

const Header = ({img, name, type}) =>(
  <Container>
      <Img src={img} />  
      <div>
        <h2>{name} - {Translate(type)}</h2> 
        <h4>{Translate('See your customer opinions!')}</h4> 
      </div>
  </Container>
);
export default Header;