import { colors } from 'resources/Theme';

const colorTag = {
    "Fast": colors.green,
    "Perfect": colors.green,
    "Fast and flowing": colors.green,
    "Ok": colors.gold,
    "Adequate":colors.gold,
    "Slow": colors.red,
    "Incorrect": colors.red,
    "Always Delayed" : colors.red,
    "No Opinion": colors.gray,
    "Smiling": colors.green,
    "Not Smiling": colors.red,
    "Frowing": colors.red,
    "Too much": colors.red,
    "Super Friendly": colors.green,
    "Friendly": colors.gold,
    "Unfriendly": colors.red,
    }
    
    export default function Color(tag) {
        return colorTag[tag] ? colorTag[tag] : colors.secundary;
     }