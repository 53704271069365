const ES = {
    "server":"MESERO",
    "See your customer opinions!" : "¡Vea las opiniones de sus clientes!",
    "Your Rating and Ranking":"Tu calificación y clasificación",
    "Today":"Hoy",
    "This Week": "Esta semana",
    "This Month" : "Este mes",
    "Lifetime" : "Toda la vida",
    "Your Customer’s Experiences": "Las experiencias de sus clientes",
    "Speed": "Velocidad",
    "Food": "Comida",
    "Drink": "Bebida",
    "Fast": "Rápido",
    "Perfect": "Perfección",
    "Fast and flowing":"Rápido y fluido",
    "Ok": "Ok",
    "Adequate":"Adecuada",
    "Slow":"Lento",
    "Incorrect": "Incorrecta",
    "Always Delayed" : "Siempre retrasado",
    "No Opinion": "Sin opinión",
    "Customers opinion of you": "Opinión de los clientes sobre ti",
    "Smiling":"Sonriente",
    "Not Smiling": "No sonrie",
    "Frowing": "Ceñudo",
    "Too much": "Demasiadas",
    "Super Friendly":"Súper amigable",
    "Friendly":"Amigable",
    "Unfriendly":"Poco amigable",
}

export default ES;