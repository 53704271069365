import { useState, useEffect } from "react";
import { format, subDays, formatDistance } from "date-fns";
import { useTranslation } from "react-i18next";
import { es } from "date-fns/locale";
export const DATE_FORMAT = 'yyyy-MM-dd';
export const DISPLAY_DATE_FORMAT = "MMM d''yy";

const useFormat = (date) => {
  const [newDate, setNewDate] = useState();
  const { t } = useTranslation();


  useEffect(() => {
    if (!!date) {
      const [y, m, d] = date.split("-");
      const subOneDay = subDays(new Date(+y, +m - 1, d), 1);
      setNewDate(format(subOneDay, "EEEE, MMM d, yy", {locale: es}));
    }

    console.log()
  }, [date]);

  return newDate;
};

const capitalize = string => string.split(" ").map(item => item.charAt(0).toLocaleUpperCase() + item.slice(1)).join(' ')

export const getFormattedDate = (date, dateFormat = DATE_FORMAT) => {
  const dateFormatted = format(new Date(date), dateFormat);

  return capitalize(dateFormatted);
}

export const elapsedDays = (date) => {
  if(!date) return ""
  const dateToObj = dateFromString(date);

  return formatDistance(dateToObj, new Date());
};

export const dateFromString = (dateString) => {
  if(!dateString) return ""
  const [y, m, d] = dateString.substr(0,10).split("-");
  return new Date(+y, +m - 1, d);
};

export default useFormat;
