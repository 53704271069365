import React from 'react';
import styled from 'styled-components';
import PieColumn from '../components/PieColumn';
import Translate from 'i18n/translate';
import { device } from 'resources/Theme';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 15px;
    width: 100%;
    margin: 30px auto 18px;
    justify-content: center;

    @media ${device.tablet} { 
      margin: 20px 0 8px;
    }
    @media ${device.mobile} { 
      margin: 18px 0 3px;
    }
`;

function Experiences({data}) {
  const tags =  !!data && Object.keys(data).map(k => [k, data[k]] );
  return (
    <>
    <h3 className='top'>{Translate('Your Customer’s Experiences')}</h3>
    <Container >
      {!!tags && tags.map((tag, i) => <PieColumn key={i} data={tag}/>)}  
    </Container>
    </>
  );
}

export default Experiences;