import React from 'react';
import styled from 'styled-components';
import Regular from '../resources/images/stars/star.svg';
import Empty from '../resources/images/stars/empty-star.svg';
import LessOne from '../resources/images/stars/0.5star.svg';
import One from '../resources/images/stars/1star.svg';
import LessTwo from '../resources/images/stars/1.5star.svg';
import Two from '../resources/images/stars/2star.svg';
import LessThree from '../resources/images/stars/2.5star.svg';
import Three from '../resources/images/stars/3star.svg';
import LessFour from '../resources/images/stars/3.5star.svg';
import Four from '../resources/images/stars/4star.svg';
import LessFive from '../resources/images/stars/4.5star.svg';
import Five from '../resources/images/stars/5star.svg';
import { device } from 'resources/Theme';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: auto;
`;
const Star = styled.img`
    margin: 0 auto;
    width: calc(12px + 1vw);
    height: calc(12px + 1vw);
    @media ${device.laptop} { 
        width: calc(12px + 1.3vw);
        height: calc(12px + 1.3vw);
    }
    @media ${device.mobile} { 
      width: 12px;
      height: 12px;
    }
`;
const Item = styled.div`
    margin: 0 auto;
    width: auto;
`;

const Stars = ({value, id}) => {
    let score = [];
    const state = [
        [<Star src={Empty} />,<Star src={Empty} />,<Star src={Empty} />,<Star src={Empty} />,<Star src={Empty} />] ,
        [<Star src={LessOne} />,<Star src={Empty} />,<Star src={Empty} />,<Star src={Empty} />,<Star src={Empty} />],
        [<Star src={One} />,<Star src={Empty} />,<Star src={Empty} />,<Star src={Empty} />,<Star src={Empty} />],
        [<Star src={Regular} />,<Star src={LessTwo} />,<Star src={Empty} />,<Star src={Empty} />,<Star src={Empty} />],
        [<Star src={Regular} />,<Star src={Two} />,<Star src={Empty} />,<Star src={Empty} />,<Star src={Empty} />],
        [<Star src={Regular} />,<Star src={Regular} />,<Star src={LessThree} />,<Star src={Empty} />,<Star src={Empty} />],
        [<Star src={Regular} />,<Star src={Regular} />,<Star src={Three} />,<Star src={Empty} />,<Star src={Empty} />],
        [<Star src={Regular} />,<Star src={Regular} />,<Star src={Regular} />,<Star src={LessFour} />,<Star src={Empty} />],
        [<Star src={Regular} />,<Star src={Regular} />,<Star src={Regular} />,<Star src={Four} />,<Star src={Empty} />],
        [<Star src={Regular} />,<Star src={Regular} />,<Star src={Regular} />,<Star src={Regular} />,<Star src={LessFive} />],
        [<Star src={Regular} />,<Star src={Regular} />,<Star src={Regular} />,<Star src={Regular} />,<Star src={Five} />],
    ];
    if (value < 1 ) {
        score = state[1];
    } if (value === 1 ) {
        score = state[2];
    }if ( (1 < value) && (value < 2) ) {
        score = state[3];
    }if (value === 2 ) {
        score = state[4];
    }if ( (2 < value) && (value < 3) ) {
        score = state[5];
    }if (value === 3 ) {
        score = state[6];
    }if ( (3 < value) && (value < 4) ) {
        score = state[7];
    }if (value === 4 ) {
        score = state[8];
    }if ( (4 < value) && (value < 5) ) {
        score = state[9];
    }if (value === 5 ) {
        score = state[10];
    }
return (
    <Container>
        { (value === 0) ? score = state[0] :
          (value === '-') ? score = state[0] :    
            score.map((stars, i) =>
                <Item key={id + i} data-key={id + i}>
                    {stars}
                </Item>
        )}
    </Container>
    );
};

export default Stars;