import React from "react";
import Mail from "containers/Mail";
import InactiveTable from "./components/InactiveTable";
import DisabledTable from "./components/DisabledTable";
import { MANAGER_URL } from "resources/constants/urls";
import styled from 'styled-components';

const Empty = styled.div`
  width: auto;
  margin: 0 auto;
  text-align: center;
  color: white;
  font-size: 30px;
  font-weight: 600;
  margin-top: 50px;
  h4{
    margin-bottom: 20px;
  }
`;


const Inactive = ({ data, isPreview }) => {
  const filter = data.inactive86.length > 0 ? "inactive" : "disable";
  
  const visible = (data?.inactive86.length > 0) || (data?.disabled.length > 0) ? true : false;

  return (
    <Mail 
      show={visible} 
      buttonText="Go To Solve" 
      link={`${MANAGER_URL}/menu/items/management-86`}
      isPreview={isPreview}
      group={data?.group ?? 'sma'}>
      {data?.inactive86.length > 0 ? (
        <InactiveTable title="Inactive" data={data?.inactive86} />
      ) 
      : 
      (<Empty> 
          <h4>No Items at 86's status nor unactive.</h4> 
          Great Job!
        </Empty>)
      }
     
      {data?.disabled.length > 0 && (
        <DisabledTable title="Items Disabled Yesterday" data={data?.disabled} />
      )}
    </Mail>
  );
};
export default Inactive;
