import React from 'react';
import {DISPLAY_DATE_FORMAT, elapsedDays, getFormattedDate} from 'resources/utils/date';
import {
  Container,
  Header,
  Grid,
  Row
} from './styles';

const InactiveTable = ({title, data}) => {
  const columns = 4;
  
  return (
    <Container>

      <h3>{title}</h3>

      <Header columns={columns}>
          <div>Item Name</div>
          <div>Who made the change</div>
          <div>Date of change</div>
          <div>Days since 86</div>
      </Header>
      
      <Grid>
        { data?.sort((a,b)=> new Date(b.date) - new Date(a.date)).map((value, i) =>    
          <Row 
            columns={columns} 
            className='row' 
            key={i}
            >
              <div>{value.product}</div>
              <div>{value.user}</div>
              <div>{getFormattedDate(value.date, DISPLAY_DATE_FORMAT)}</div>
              <div>{elapsedDays(value.date)}</div>
          </Row> 
        )}  
      </Grid>
    </Container>
    );
};

export default InactiveTable;