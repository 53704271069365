import React from 'react';
import styled from 'styled-components';
import { device, colors } from 'resources/Theme';
import Icon from 'resources/images/calendar-Icon.svg';
import useFormat from 'resources/utils/date';

const Container = styled.div`
    border-radius: 24px 24px 0px 0px;
    background-color: ${colors.primary};
    color: ${colors.white};
    height: calc(100% - 130px);
    width: min(100%, 900px);
    margin: 0 auto;
    padding-bottom: 33px;

  @media ${device.tablet} { 
      height: calc(100% - 80px);
      width: min(100%, 700px);
    }
`;

const Img = styled.img`
    margin: 0px 10px;
    width: calc(20px + 1vw);
    height: calc(20px + 1vw);
    @media ${device.tablet} { 
      width: 20px;
      height: 20px;
    }
`;
const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: max(44px, 50px, 60px);
    border-radius: 24px 24px 0px 0px;
    background-color: ${colors.secundary};
    color: ${colors.blue};
    @media ${device.mobile} { 
        height: 44px;
      }
    h3{
      text-transform: capitalize;
    }
`;

const Content = styled.div`
    display: grid;
    grid-template-rows: repeat(auto-fit, 1fr);
    align-items: center;
    width: 93%;
    margin: 10px auto;
    text-align: center;

    @media ${device.mobileS} { 
      width: 100%;
    }
`;

const Card = ({children, date}) => {
  const dateValidate = !!date && date;  
  const newDate = useFormat(dateValidate);
    return (
      <Container>
          <TopContainer>
              <Img src={Icon} />  
              <h3>{newDate}</h3> 
          </TopContainer>
          <Content>
              {children}
          </Content>
      </Container>
    );
}

export default Card;