import React from 'react';
import styled from 'styled-components';
import { colors } from 'resources/Theme';
import { device } from 'resources/Theme';

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    width: fit-content;
    margin-top: 15px;
    text-align: left   ;

    span {
        padding-top: 3px;
    }

    @media ${device.mobile} { 
        margin-top: 12px;
      }
`;
const Col = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
`;
const Circle = styled.div`
    background: ${({color}) => ( color || colors.clear)};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 0.6rem;
    margin-top: 0.2rem;

    @media ${device.mobile} { 
        width: 8px;
        height: 8px;
        margin-right: 0.5rem;
      }
`;

const PercentItem = ({title, percent, total, color}) => (
    <Container>
        <Circle color={color}/>
        <Col>
            <label>{title ? title : '--'}</label>   
            <span>{percent ? percent : '0' }% - {total ? total : '0'}</span>
        </Col>
    </Container>
);

export default PercentItem;