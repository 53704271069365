import styled from 'styled-components';
import { colors } from 'resources/Theme';

const Container = styled.div `
    width: min(609px , 95%);
    margin: 30px auto 0;
    padding: 20px 0;
    text-align: center;
    background: ${colors.white};
    border-radius: 24px;
    align-self: end;

    h3 {
        margin-bottom: 15px;
    }
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: repeat(${({columns}) =>(columns)}, 1fr);
    grid-template-rows: 64px;
    font-size: 14px;
    font-weight: 600;
    border-top: 1px solid ${colors.disabled};
    justify-content: center;
    align-items: center;
`;
const Grid = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    
    .row:nth-child(2n+1) {
      background: ${colors.disabled};
    }
`;
const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(${({columns}) =>(columns)}, 1fr);
    grid-template-rows: 64px;
    font-size: 14px;
   
    div {
      display: grid;
      width: 95%;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: left;
      margin: 0 auto;
    }
`;

export {
    Container,
    Header,
    Grid,
    Row
};