import React from 'react';
import styled from 'styled-components';
import { colors } from 'resources/Theme';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
`;

const BaseButton = styled.a`  
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background: ${({ color }) => (color || colors.blue)};
    color: ${colors.white};
    padding: 0 10px;
    border: 1px solid ${({ color }) => (color || colors.blue)};
    font-size: 14px;
    margin: 16px 0;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: -1px 4px 18px 5px ${({ shadow }) => (shadow || colors.shadow)};
    -webkit-box-shadow: -1px 4px 18px 5px ${({ shadow }) => (shadow || colors.shadow)};
    -moz-box-shadow: -1px 4px 18px 5px ${({ shadow }) => (shadow || colors.shadow)};
    width: 188px;
    height: 48px;
    &:focus {
    outline: 1px solid ${({ color }) => (color || colors.blue)};;
    }
    &:hover {
    opacity: 0.8;
    }
    &:active {
    background: ${colors.white};
    color: ${colors.blue};
    border: 2px solid ${colors.blue};
    } 
`;

const LinkButton = ({ text='', link, disabled}) => (
    <Center>
      <BaseButton
      type='button'
      color={!!disabled ? colors.disabled : colors.blue}
      shadow={!!disabled ? colors.disabled : colors.shadow}
      href={link}
      >
      {text}
    </BaseButton>
    </Center>
);

export default LinkButton;